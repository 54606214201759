import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import {
  Observable,
  map,
  shareReplay,
  filter,
  withLatestFrom,
  takeUntil,
  Subject
} from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() sidenav!: MatSidenav;

  private readonly _destroying$ = new Subject<void>();

  accountName: string | undefined = '';
  headerEnvironmentInfo: string | undefined = '';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService
  ) {}

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  ngOnInit(): void {
    this.headerEnvironmentInfo = environment.headerEnvironmentInfo;
    this.router.events
      .pipe(
        withLatestFrom(this.isHandset$),
        filter(([a, b]) => b && a instanceof NavigationEnd)
      )
      .subscribe(() => this.sidenav.close());

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  setLoginDisplay() {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount) {
      this.accountName = activeAccount.name;
    }
  }

  logout(): void {
    this.authService.logoutRedirect();
  }
}
