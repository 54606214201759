import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PositionsResponse } from '../models/positions-response';

@Injectable({
  providedIn: 'root'
})
export class PositionService {

  private url: string = environment.apiUrl + '/position';

  constructor(private httpClient: HttpClient) { }

  getPositions(): Observable<PositionsResponse> {
    return this.httpClient.get<PositionsResponse>(this.url);
  }
}
