import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WarningDialogData } from '../../models/warning-dialog-data';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent {
  @Input() type?: string;
  @Input() title?: string;
  @Input() message?: string;
  @Input() cancelButton?: string;
  @Input() button?: string;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WarningDialogData
  ) {
    this.type = data.type;
    this.title = data.title;
    this.message = data.message;
    this.button = data.button;
    this.cancelButton = data.cancelButton;
  }

  cancel() {
    this.dialogRef.close('canceled');
  }

  close() {
    if (this.type === 'confirmation') {
      this.router.navigate(['/admin'], { state: { bypassGuard: true } });
    }
    this.dialogRef.close();
  }
}
