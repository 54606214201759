import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ConnectionService, ConnectionState } from 'ng-connection-service';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { WarningDialogComponent } from 'src/app/report-form/shared/components/warning-dialog/warning-dialog.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public connectionState: ConnectionState = {
    hasInternetAccess: true,
    hasNetworkConnection: true
  };
  public connectionState$: Observable<ConnectionState>;
  private onlineStatus = new BehaviorSubject<boolean>(true);
  public onlineStatus$: Observable<boolean> = this.onlineStatus.asObservable();
  public updateAvailable = false;

  constructor(
    private swUpdate: SwUpdate,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private connectionService: ConnectionService
  ) {
    this.connectionState$ = this.connectionService.monitor({
      enableHeartbeat: true,
      heartbeatInterval: 10000,
      heartbeatRetryInterval: 5000,
      heartbeatUrl: environment.apiUrl + '/healthcheck/internetcheck',
      requestMethod: 'get'
    });

    this.connectionState$.subscribe((currentState: ConnectionState) => {
      console.log('Connection state changed: ', currentState);
      this.connectionState = currentState;
      this.onlineStatus.next(
        currentState.hasInternetAccess && navigator.onLine
      );
    });
  }

  isOnline(): boolean {
    const hasInternetAccess = this.connectionState?.hasInternetAccess ?? true;
    return hasInternetAccess && navigator.onLine;
  }

  notifyOnUpdate(): void {
    this.swUpdate.versionUpdates.subscribe((event) => {
      switch (event.type) {
        case 'VERSION_DETECTED':
          console.log('Update available: ' + event.version.hash);
          this.snackbar.open(`Downloading update...`, undefined, {
            duration: 10000
          });
          break;
        case 'VERSION_READY':
          console.log(
            'Update downloaded: ' +
              event.currentVersion +
              ' => ' +
              event.latestVersion
          );
          this.updateAvailable = true;
          this.showSnackBarForUpdate(1);
          break;
        default:
          break;
      }
    });
  }

  showSnackBarForUpdate(showSnackBarRertyCount: number) {
    const snack = this.snackbar.open(
      `New version of this app is available!`,
      `Update now`
    );
    snack
      .onAction()
      .pipe(
        switchMap(() => {
          if (
            this.router.routerState.snapshot.url == '/report-form' ||
            this.router.routerState.snapshot.url.startsWith(
              '/report-form/saved-reports/'
            )
          ) {
            this.dialog
              .open(WarningDialogComponent, {
                data: {
                  type: 'update',
                  title: 'Warning',
                  button: 'Update',
                  message:
                    'Update will refresh the screen. Any unsaved changes will be lost.',
                  cancelButton: 'Cancel'
                }
              })
              .afterClosed()
              .subscribe((res) => {
                if (res == 'canceled') {
                  if (showSnackBarRertyCount < 6) {
                    showSnackBarRertyCount = showSnackBarRertyCount + 1;
                    this.showSnackBarForUpdate(showSnackBarRertyCount);
                  }
                } else {
                  this.swUpdate
                    .activateUpdate()
                    .then(() => {
                      this.updateAvailable = false;
                      window.location.reload();
                    });
                }
              });

            return of(false);
          } else {
            return of(true);
          }
        })
      )
      .subscribe((res) => {
        if (res) {
          this.updateAvailable = false;
          window.location.reload();
        }
      });
  }
}
