import { Injectable } from '@angular/core';
import { ProblemService } from './problem.service';
import { AircraftTypeService } from 'src/app/report-form/shared/services/aircraft-type.service';
import { AircraftService } from 'src/app/report-form/shared/services/aircraft.service';
import { PositionService } from 'src/app/report-form/shared/services/position.service';
import { ReportService } from 'src/app/report-form/shared/services/report.service';
import { FatigueReportService } from 'src/app/report-form/shared/services/fatigue-report.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  constructor(
    private aircraftTypeService: AircraftTypeService,
    private aircraftService: AircraftService,
    private positionService: PositionService,
    private reportService: ReportService,
    private problemService: ProblemService,
    private fatigueReportService: FatigueReportService
  ) {}

  public loadAndCacheDictionaries() {
    this.aircraftTypeService.getAircraftTypes().subscribe({
      next: (response) => {
        if (response?.aircraftTypes) {
          response.aircraftTypes.forEach((aircraftType) => {
            this.aircraftService.getAircrafts(aircraftType.id).subscribe();
          });
        }
      }
    });
    this.positionService.getPositions().subscribe();
    this.reportService.getFlightNumberAirports().subscribe();
    this.problemService.getCategoriesWithProblems().subscribe();
    this.problemService.getCategoriesWithProblems(true).subscribe();
    this.fatigueReportService.getAircraftTypes().subscribe();
    this.fatigueReportService.getAlertnessLevels().subscribe();
    this.fatigueReportService.getConfidentialityLevels().subscribe();
    this.fatigueReportService.getContributoryFactors().subscribe();
    this.fatigueReportService.getFatigueCategories().subscribe();
    this.fatigueReportService.getSymptomTypes().subscribe();
    this.fatigueReportService.getSymptoms().subscribe();
    this.fatigueReportService.getTimeZoneData().subscribe();
  }
}
