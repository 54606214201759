import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AircraftTypesResponse } from '../models/aircraft-types-response';

@Injectable({
  providedIn: 'root'
})
export class AircraftTypeService {
  private url: string = environment.apiUrl + '/aircrafttype';

  constructor(private httpClient: HttpClient) {}

  getAircraftTypes(): Observable<AircraftTypesResponse> {
    return this.httpClient.get<AircraftTypesResponse>(this.url);
  }
}
