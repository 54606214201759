import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AircraftResponse } from '../models/aircraft-response';

@Injectable({
  providedIn: 'root'
})
export class AircraftService {
  private url: string = environment.apiUrl + '/aircraft';

  constructor(private httpClient: HttpClient) {}

  getAircrafts(aircraftTypeId?: number | null): Observable<AircraftResponse> {
    if (aircraftTypeId) {
      const params = {
        aircraftTypeId: aircraftTypeId
      };
      return this.httpClient.get<AircraftResponse>(this.url, { params });
    }
    return this.httpClient.get<AircraftResponse>(this.url);
  }
}
