import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoriesWithProblemsResponse } from 'src/app/admin/shared/models/categories-with-problems-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProblemService {
  private url: string = environment.apiUrl + '/problem';

  constructor(private httpClient: HttpClient) { }

  getCategoriesWithProblems(includeDeletedProblems: boolean = false): Observable<CategoriesWithProblemsResponse> {
    let fullUrl = `${this.url}/categoriesWithProblems`;
    if (includeDeletedProblems) {
      fullUrl = `${fullUrl}?includeDeletedProblems=true`;
    }
    return this.httpClient.get<CategoriesWithProblemsResponse>(fullUrl);
  }
}
